// App
import { authClient } from '@neptune/shared/core-auth-domain';

authClient.init({});

// We could just use "login" method of keycloak adapter but it uses window.location.href assignment so that it stays
// in browser history which makes back button working badly in our case.
// That is why we manually create login url and replace the current page in browser history
const loginUrl = authClient.createLoginUrl({
  redirectUri: window.location.origin,
});

window.location.replace(loginUrl);
